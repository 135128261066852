.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.verify-page {
  /*保存按钮*/
}
.verify-page .verify-group {
  background: #fff;
  padding-right: 0.22rem;
  padding-left: 0.26rem;
}
.verify-page .verify-group .yd-cell-item {
  padding-left: 0;
}
.verify-page .verify-group .yd-cell-box {
  margin-bottom: 0;
}
.verify-page .verify-group .yd-cell-left {
  width: 1.6rem;
  font-size: 0.32rem;
  color: #000;
}
.verify-page .verify-group .yd-cell-right {
  width: 3.9rem;
  height: 1.18rem;
  padding-right: 0;
}
.verify-page .verify-group input {
  font-size: 0.32rem;
  color: #333;
}
.verify-page .verify-group input input::placeholder {
  font-size: 0.32rem;
  color: #999;
}
.verify-page .verify-group .verify-item {
  width: 100%;
  height: 1rem;
}
.verify-page .verify-group .verify-item .verify-right {
  width: 3rem;
  height: 1rem;
}
.verify-page .verify-group .verify-item .yd-btn-warning:not(.yd-btn-loading) {
  background: #fff;
  font-size: 0.28rem;
  color: var(--main-color);
}
.verify-page .verify-group .verify-item .yd-btn-disabled {
  background: #fff;
  font-size: 0.28rem;
  color: #999;
}
.verify-page .verify-group .verify-item .yd-btn {
  padding: 0 0.1rem;
}
.verify-page .verify-group .verify-img {
  height: 0.8rem;
  min-width: 1.2rem;
  position: absolute;
  right: 0.12rem;
  width: 2.4rem;
}
.verify-page .verify-group .verify-num {
  position: absolute;
  right: 0.12rem;
  font-size: 0.28rem;
  color: var(--main-color);
}
.verify-page .verify-group .again {
  font-size: 0.28rem;
  color: #999;
}
.verify-page .save-box {
  width: 100%;
  height: 0.88rem;
  padding: 0 0.24rem;
  margin-top: 0.5rem;
}
.verify-page .save-box .save-btn {
  width: 100%;
  height: 100%;
  background: var(--main-color);
  border-radius: 1rem;
  line-height: 0.88rem;
  font-size: 0.32rem;
  color: #fff;
  text-align: center;
}
.verify-page .save-box button {
  border: 0;
}
.verify-page .save-box button[disabled] {
  opacity: 0.4;
}
